@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");

//colors variables
$primary-clr: #2463eb;
$whrite-clr: #ffffff;
$green-clr: #069668;
$black-clr: #10172a;

//layouts variables
$desktop-width: calc(100vw - (120px * 2));
$min-desktop-width: calc(100vw - (80px * 2));
$tablet-width: calc(100vw - (52px * 2));
$mobile-width: calc(100vw - (18px * 2));

//fonts variables
$title-font: "Open Sans", sans-serif;
$main-font: "Noto Sans", sans-serif;

@mixin mobile-platform {
  @media screen and (max-width: 520px) {
    @content;
  }
}
