@import url("https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap");
@import "../sass/vars";

*,
*::after,
*::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body,
html {
  background-color: #f7fafc;
  font-family: $main-font;
  font-optical-sizing: auto;
  scroll-behavior: smooth;
}

.header {
  &__container {
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
  }
}

.navbar {
  &__container {
    width: $desktop-width;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px 0;

    @include mobile-platform {
      width: $mobile-width;
    }

    & > button {
      display: none;

      @include mobile-platform {
        display: block !important;
        all: unset;
        cursor: pointer;

        & > svg {
          width: 24px;
          height: 24px;
          stroke-width: 2px;
          color: $whrite-clr;
        }
      }
    }
  }

  &__logo {
    font-size: 22px;
    font-weight: 600;
    font-style: normal;
    font-optical-sizing: auto;
    color: $whrite-clr;
  }

  &__navigation {
    display: inline-flex;
    gap: 60px;
    list-style: none;

    @include mobile-platform {
      display: none;
    }
  }

  &__link {
    text-decoration: none;
    width: max-content;
    height: 30px;
    position: relative;
    overflow-x: hidden;

    & > a {
      text-decoration: none;
      font-size: 16px;
      text-transform: uppercase;
      color: $whrite-clr;
      font-weight: 500;
      letter-spacing: 1px;
      transition: color 250ms ease-out;
    }

    &::after {
      content: "";
      width: 100%;
      height: 5px;
      position: absolute;
      background-color: $green-clr;
      left: 0;
      bottom: 0;
      transform: translateX(-100%);
      transform-origin: right;
      transition: transform 250ms linear;
    }

    &:hover {
      & > a {
        color: $green-clr;
      }
      &::after {
        transform: translateX(0);
        transform-origin: right;
      }
    }
  }
}

.banner {
  &__container {
    width: 100vw;
    height: auto;
    background-image: url("../../public/busness.jpg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    padding-bottom: 80px;
  }

  &__wrapper {
    width: 100vw;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba($color: #000000, $alpha: 0.5);
  }

  &__box {
    width: $desktop-width;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-bottom: 100px;

    @include mobile-platform {
      width: $mobile-width;
      flex-direction: column;
      padding-bottom: 0;
      gap: 40px;
    }

    &--left {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 650px;

      @include mobile-platform {
        width: 100%;
        gap: 20px;
        padding-top: 140px;
      }

      & > h1 {
        font-size: 72px;
        font-weight: 700;
        color: $whrite-clr;
        font-style: normal;
        line-height: 85px;

        @include mobile-platform {
          font-size: 32px;
          line-height: 42px;
        }
      }

      & > p {
        font-size: 18px;
        font-weight: 400;
        color: $whrite-clr;
        font-style: normal;
        width: 587px;

        @include mobile-platform {
          width: 100%;
          line-height: 28px;
        }
      }

      &__raiting {
        width: max-content;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 20px;

        @include mobile-platform {
          width: 100%;
          gap: 10px;
        }

        & > hr {
          all: unset;
          height: 20px;
          width: 1px;
          background-color: #cbd5e1;
        }

        & > span {
          font-size: 14px;
          font-weight: 500;
          font-style: normal;
          color: $whrite-clr;

          & > strong {
            color: $green-clr;
          }
        }
      }

      &__stars {
        width: auto;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 5px;
        position: relative;

        @include mobile-platform {
          width: max-content;
          gap: 2px;
          align-items: flex-start;
        }

        & > svg {
          width: 26px;
          height: 26px;
          color: #eab305;

          @include mobile-platform {
            width: 18px;
            height: 18px;
          }
        }

        &--colored {
          width: 92%;
          height: auto;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          gap: 5px;
          position: absolute;
          left: 0;
          overflow: hidden;

          @include mobile-platform {
            gap: 2px;
            width: auto;
            width: 90%;
          }

          & > svg {
            min-width: 26px;
            height: 26px;
            color: #eab305;

            @include mobile-platform {
              min-width: 18px;
              width: 18px;
              height: 18px;
            }
          }
        }
      }
    }

    &--right {
      display: flex;
      flex-direction: column;
      gap: 10px;
      background-color: $whrite-clr;
      width: 450px;
      padding: 40px;
      border-radius: 10px;
      position: relative;

      @include mobile-platform {
        width: 100%;
      }

      & > h2 {
        font-size: 30px;
        font-weight: 700;
        color: black;
        font-style: normal;

        @include mobile-platform {
          font-size: 24px;
        }
      }

      & > span {
        font-size: 16px;
        font-weight: 400;
        color: black;
        font-style: normal;

        @include mobile-platform {
          font-size: 14px;
          line-height: 20px;
        }
      }

      &--inputs {
        display: flex;
        flex-direction: column;
        gap: 25px;

        @include mobile-platform {
          gap: 18px;
        }

        &__tel {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;

          & > input {
            all: unset;
            width: 100%;
            height: auto;
            border-bottom: 1px solid black;
            padding: 10px 0px 10px 10px;
            font-size: 18px;
            color: black;
            font-style: normal;
            font-weight: 500;

            @include mobile-platform {
              font-size: 14px;
              font-weight: 600;
            }
          }

          &--index {
            height: 45px;
            width: max-content;
            display: flex;
            gap: 10px;
            position: relative;
            padding-right: 10px;
            align-items: center;
            cursor: pointer;

            & > img {
              width: 32px;
              height: 22px;

              @include mobile-platform {
                width: 24px;
                height: 24px;
              }
            }

            &__options {
              position: absolute;
              display: flex;
              flex-direction: column;
              background-color: $whrite-clr;
              left: 0;
              bottom: -120px;
              opacity: 0;
              z-index: 1;
              transition: opacity 300ms ease-out;
              border-radius: 10px;
              box-shadow: 1px 1px 3px $black-clr;
              max-height: 120px;
              overflow: scroll;

              &.open {
                opacity: 1;
              }

              & > div {
                height: auto;
                width: 100px;
                display: flex;
                gap: 5px;
                position: relative;
                padding-right: 10px;
                border-bottom: 1px solid $black-clr;
                align-items: center;
                cursor: pointer;
                padding: 10px;

                @include mobile-platform {
                  gap: 10px;
                }

                & > img {
                  width: 32px;
                  height: 22px;

                  @include mobile-platform {
                    width: 24px;
                    height: 24px;
                  }
                }
              }
            }
          }
        }

        &__loader {
          position: absolute;
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          background-color: rgba($color: $whrite-clr, $alpha: 0.8);
          left: 0;
          top: 0;
          border-radius: 10px;

          & > svg {
            width: 68px;
            height: 82px;
            color: $green-clr;
            transform: rotateX(180deg);
            -webkit-animation: rotating 2s linear infinite;
            -moz-animation: rotating 2s linear infinite;
            -ms-animation: rotating 2s linear infinite;
            -o-animation: rotating 2s linear infinite;
            animation: rotating 2s linear infinite;
          }
        }
      }

      &--input {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 5px;
        align-items: flex-start;
        justify-content: flex-start;

        & > input {
          all: unset;
          width: calc(100% - 10px);
          height: auto;
          border-bottom: 1px solid black;
          padding: 10px 0px 10px 10px;
          font-size: 18px;
          color: black;
          font-style: normal;
          font-weight: 500;

          @include mobile-platform {
            font-size: 14px;
            font-weight: 600;
          }
        }

        & > span {
          font-size: 14px;
          font-weight: 500;
          color: rgba($color: red, $alpha: 0.5);
        }
      }

      & > button {
        all: unset;
        width: 100%;
        height: 60px;
        background-color: $primary-clr;
        border-radius: 10px;
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        font-weight: 400;
        color: $whrite-clr;
        cursor: pointer;

        @include mobile-platform {
          font-size: 14px;
          font-weight: 600;
          height: 50px;
        }
      }
    }
  }
}

.info {
  &__container {
    width: 100vw;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $whrite-clr;
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
    -webkit-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.2);
  }

  &__wrapper {
    width: calc(100vw - (220px * 2));
    height: auto;
    padding: 50px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include mobile-platform {
      width: $mobile-width;
      padding: 30px 0;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 25px;
    }
  }

  &__card {
    width: max-content;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;

    @include mobile-platform {
      align-items: flex-start;
    }

    & > h2 {
      font-size: 48px;
      font-weight: 600;
      font-style: normal;
      background: -webkit-linear-gradient(
        135deg,
        rgba(6, 147, 227, 1) 0%,
        $green-clr 100%
      );
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;

      @include mobile-platform {
        font-size: 18px;
        width: max-content;
      }
    }

    & > p {
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      color: $black-clr;

      @include mobile-platform {
        font-size: 14px;
      }
    }
  }
}

.review {
  &__container {
    width: 100vw;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 120px 0;
    position: relative;
    z-index: 1;

    @include mobile-platform {
      padding: 60px 0;
    }

    & > .bulle {
      z-index: -10;
      position: absolute;
      width: 300px;
      height: 300px;
      top: 100px;

      @include mobile-platform {
        top: 30px;
        width: 250px;
        height: 250px;
      }

      background: hsla(202, 95%, 46%, 1);

      background: radial-gradient(
        circle,
        hsla(202, 95%, 46%, 1) 0%,
        hsla(202, 95%, 46%, 1) 0%,
        hsla(204, 45%, 98%, 1) 65%
      );

      background: -moz-radial-gradient(
        circle,
        hsla(202, 95%, 46%, 1) 0%,
        hsla(202, 95%, 46%, 1) 0%,
        hsla(204, 45%, 98%, 1) 65%
      );

      background: -webkit-radial-gradient(
        circle,
        hsla(202, 95%, 46%, 1) 0%,
        hsla(202, 95%, 46%, 1) 0%,
        hsla(204, 45%, 98%, 1) 65%
      );
    }
  }

  &__wrapper {
    width: $desktop-width;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 60px;

    @include mobile-platform {
      width: $mobile-width;
      gap: 40px;
    }
  }

  &__heading {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    justify-content: center;

    & > h2 {
      font-size: 16px;
      font-weight: 600;
      font-style: normal;
      text-transform: uppercase;
      background: -webkit-linear-gradient(135deg, #0693e3 0%, $green-clr 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    & > p {
      font-size: 48px;
      font-weight: 700;
      color: $black-clr;
      text-align: center;
      width: 850px;
      line-height: 50px;

      @include mobile-platform {
        font-size: 26px;
        line-height: 32px;
        width: 100%;
      }
    }
  }

  &__cards {
    width: 100%;
    overflow-x: scroll;
    scroll-behavior: smooth;
    overflow-y: visible;
    height: 252px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none;
    }

    &--wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      width: max-content;
      height: 250px;
      gap: 40px;
      padding: 0 1px;

      @include mobile-platform {
        gap: 20px;
      }
    }
  }

  &__card {
    width: 450px;
    height: 250px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);

    @include mobile-platform {
      width: 320px;
    }

    &--heading {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 20px;

      @include mobile-platform {
        gap: 10px;
      }

      &__picture {
        position: relative;
        min-width: 60px;
        min-height: 60px;
        border-radius: 50%;
        overflow: hidden;

        & > img {
          position: absolute;
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center center;
        }
      }

      &__content {
        width: 100%;
        height: auto;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include mobile-platform {
          flex-direction: column;
          align-items: flex-start;
        }

        & > h3 {
          font-size: 22px;
          font-weight: 600;
          font-style: normal;
          color: $green-clr;

          @include mobile-platform {
            font-size: 18px;
          }
        }

        & > span {
          font-size: 18px;
          font-weight: 600;
          font-style: normal;
          color: $green-clr;

          @include mobile-platform {
            font-size: 16px;
          }

          & > strong {
            font-size: 18px;
            font-weight: 400;
            font-style: normal;
            color: $black-clr;

            @include mobile-platform {
              font-size: 16px;
              font-weight: 500;
            }
          }
        }
      }
    }

    &--description {
      width: 100%;
      font-size: 18px;
      font-weight: 400;
      color: $black-clr;

      @include mobile-platform {
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
}

.overview {
  &__container {
    width: $desktop-width;
    height: auto;
    background-color: #f1f5f9;
    margin: auto;
    border-radius: 10px;

    @include mobile-platform {
      width: $mobile-width;
    }
  }

  &__wrapper {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 60px;

    @include mobile-platform {
      gap: 20px;
    }
  }

  &__heading {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 40px 40px 0 40px;

    @include mobile-platform {
      padding: 10px;
      gap: 10px;
    }

    & > h2 {
      font-size: 36px;
      font-weight: 600;
      color: $black-clr;

      @include mobile-platform {
        font-size: 26px;
      }
    }

    & > p {
      font-size: 16px;
      font-weight: 300;
      color: $black-clr;

      @include mobile-platform {
        font-size: 14px;
      }
    }
  }

  &__cards {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
  }

  &__card {
    width: 100%;
    height: auto;
    padding: 30px 40px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    border-top: 1px solid rgba($color: #000000, $alpha: 0.2);

    @include mobile-platform {
      padding: 10px;
      gap: 20px;
    }

    &--left {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      gap: 20px;
      min-width: 400px;

      @include mobile-platform {
        min-width: auto;
        gap: 10px;
      }

      & > svg {
        width: 18px;
        height: 18px;
        color: $green-clr;

        @include mobile-platform {
          min-width: 18px;
        }
      }

      & > h3 {
        font-size: 18px;
        font-weight: 400;
        font-style: normal;
        color: $black-clr;

        @include mobile-platform {
          font-size: 16px;
        }
      }
    }
    & > p {
      font-size: 18px;
      font-weight: 300;
      font-style: normal;

      @include mobile-platform {
        font-size: 16px;
      }
    }
  }
}

.faq {
  &__container {
    width: 100vw;
    height: auto;
    padding: 120px 0;
    display: flex;
    align-items: center;
    justify-content: center;

    @include mobile-platform {
      padding: 60px 0;
    }
  }

  &__wrapper {
    width: $desktop-width;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 60px;

    @include mobile-platform {
      width: $mobile-width;
      gap: 40px;
    }
  }

  &__heading {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;

    & > h2 {
      font-size: 16px;
      font-weight: 600;
      font-style: normal;
      text-transform: uppercase;
      background: -webkit-linear-gradient(135deg, #0693e3 0%, $green-clr 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    & > span {
      font-size: 48px;
      font-weight: 700;
      color: $black-clr;
      text-align: center;
      width: 850px;
      line-height: 50px;

      @include mobile-platform {
        font-size: 24px;
        width: 100%;
        line-height: 28px;
      }
    }

    & > p {
      width: 780px;
      font-size: 18px;
      font-style: normal;
      font-weight: 300;
      text-align: center;

      @include mobile-platform {
        width: 100%;
        font-size: 16px;
        line-height: 22px;
      }

      & > a {
        text-decoration: underline;
        font-weight: 400;
      }
    }
  }

  &__contents {
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-wrap: wrap;

    @include mobile-platform {
      flex-direction: column;
      flex-wrap: nowrap;
      gap: 20px;
    }
  }

  &__card {
    width: calc((100% - 120px) / 2);
    height: 180px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 16px;
    cursor: pointer;
    transition: all 250ms ease-out;

    @include mobile-platform {
      width: 100%;
      border-radius: 10px;
      box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
      -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
      padding: 10px;
      height: auto;
    }

    &:hover {
      border-radius: 10px;
      box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
      -webkit-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
      -moz-box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.75);
      padding: 10px;
    }

    & > h3 {
      font-size: 18px;
      font-weight: 500;
      color: $black-clr;
      font-style: normal;
    }

    & > p {
      font-size: 16px;
      font-weight: 300;
      font-style: normal;
      color: $black-clr;
    }
  }
}

.price {
  &__container {
    height: auto;
    width: 100vw;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgb(6, 150, 104);
    background: linear-gradient(
      100deg,
      rgba(6, 150, 104, 1) 0%,
      rgba(36, 99, 235, 1) 100%
    );
    margin-top: 120px;

    @include mobile-platform {
      margin-top: 60px;
    }
  }

  &__wrapper {
    width: $desktop-width;
    padding: 120px 0;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 80px;

    @include mobile-platform {
      gap: 40px;
      width: $mobile-width;
      padding: 60px 0;
    }

    & > h2 {
      font-size: 48px;
      font-weight: 700;
      color: $black-clr;
      text-align: center;
      width: 100%;
      line-height: 50px;

      @include mobile-platform {
        font-size: 28px;
      }
    }
  }

  &__cards {
    width: 100%;
    height: auto;
    display: flex;
    align-items: start;
    gap: 30px;

    @include mobile-platform {
      flex-direction: column;
    }
  }

  &__card {
    width: 100%;
    height: 460px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: $whrite-clr;
    border-radius: 20px;
    padding: 40px 30px;
    cursor: pointer;
    transition: transform 250ms ease-out;

    @include mobile-platform {
      padding: 20px 15px;
      height: 340px;
    }

    &:hover {
      transform: scale(1.02);
    }

    & > span {
      font-size: 16px;
      font-weight: 600;
      text-transform: uppercase;
      background: -webkit-linear-gradient(135deg, #0693e3 0%, $green-clr 100%);
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }

    & > h2 {
      font-size: 20px;
      font-weight: 700;
      font-style: normal;
      color: $black-clr;
      text-transform: uppercase;
    }

    & > p {
      font-size: 18px;
      font-weight: 400;
      color: $black-clr;

      @include mobile-platform {
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
}

.footer {
  &__container {
    width: 100vw;
    height: auto;
    background-color: #f1f5f9;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__wrapper {
    width: $desktop-width;
    height: auto;
    padding: 60px 0 0 0;
    display: flex;
    flex-direction: column;
    gap: 40px;

    @include mobile-platform {
      width: $mobile-width;
    }
  }

  &__content {
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 60px;

    @include mobile-platform {
      gap: 40px;
      flex-direction: column;
    }
  }

  &__company {
    display: flex;
    width: 600px;
    flex-direction: column;
    gap: 20px;

    @include mobile-platform {
      width: 100%;
    }

    & > span {
      font-size: 28px;
      color: $black-clr;
      text-transform: uppercase;
      font-weight: 700;
      font-style: normal;

      @include mobile-platform {
        font-size: 24px;
      }
    }

    & > p {
      font-size: 16px;
      color: $black-clr;
      font-weight: 300;

      @include mobile-platform {
        font-size: 14px;
        line-height: 22px;
      }
    }
  }

  &__right {
    display: flex;
    width: max-content;
    gap: 40px;

    @include mobile-platform {
      flex-wrap: wrap;
      width: 100%;
    }
  }

  &__links {
    display: flex;
    flex-direction: column;
    gap: 5px;

    & > span {
      font-size: 18px;
      font-weight: 500;
      text-transform: uppercase;

      @include mobile-platform {
        font-size: 16px;
        font-weight: 600;
      }
    }

    & > a {
      text-transform: none;
      text-decoration: none;
      font-size: 16px;
      font-weight: 400;
      color: $black-clr;

      @include mobile-platform {
        font-size: 14px;
      }

      &:hover {
        color: $green-clr;
      }
    }
  }

  &__legal {
    display: flex;
    flex-direction: column;
    gap: 5px;

    & > span {
      font-size: 18px;
      font-weight: 500;
      text-transform: uppercase;
    }

    & > a {
      text-transform: none;
      text-decoration: none;
      font-size: 16px;
      font-weight: 400;
      color: $black-clr;

      &:hover {
        color: $green-clr;
      }
    }
  }

  &__copyright {
    width: 100%;
    border-top: 1px solid rgba($color: #000000, $alpha: 0.3);
    padding: 20px 0;

    & > p {
      font-size: 16px;
      width: 100%;
      text-align: center;
      font-weight: 500;
    }
  }
}

//ketgrame animation
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
